import React, {ReactNode} from "react";
import {Collapse as AntdCollapse} from "antd"
import './index.css';
import EditIcon from '../Icons/EditIcon';

const {Panel} = AntdCollapse;

type Props = {
    stepId: string,
    title: string,
    onEditClick?: (stepId: string) => void,
    children?: ReactNode,
    defaultActiveKey?: any[],
    editable?: boolean,
    onChange?: () => void,
    className?: string
};

const Collapse = ({defaultActiveKey, children, stepId, editable, title, onEditClick, onChange, className}: Props) => {

    return <AntdCollapse
        defaultActiveKey={defaultActiveKey}
        expandIconPosition={'right'}
        className={`collapse-item ${className}`}
        expandIcon={({ isActive }) => {
            return isActive ? <i className="material-icons">keyboard_arrow_up</i> :
                <i className="material-icons">keyboard_arrow_down</i>
        }}
        onChange={onChange}
    >
        <Panel key={stepId}
               header={title}
               extra={
                   editable && onEditClick
                       ? <EditIcon className={'collapse-item-extra'}
                                   onClick={event => {
                                       event.stopPropagation();
                                       onEditClick(stepId);
                                   }}/>
                       : null
               }
               className={'collapse-content'}
        >
            {children}
        </Panel>
    </AntdCollapse>
}

Collapse.defaultProps = {
    editable: true,
    defaultActiveKey: ['1']
};

export default Collapse
