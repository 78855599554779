import React, {ReactNode} from 'react';
import "./index.css";
import MaterialIcon from "../MaterialIcon";

type PvButtonProps = {
    children?: ReactNode,
    type?: "primary" | "primary-inverted" | "secondary" | "tertiary" | "tertiary-inverted" | "ghost" | "ghost-inverted"
    size?: "md" | "sm" | "xs"
    icon?: string,
    iconPosition?: "left" | "right",
    disabled?: boolean,
    htmlType?: "submit" | "button" | "reset",
    noPointerEvents?: boolean
}

const defaultProps = {
    type: "primary",
    size: "md",
    iconPosition: "right"
}

const Button = ({ type, size, icon, iconPosition, disabled, htmlType, noPointerEvents, ...props }: PvButtonProps & React.HTMLProps<HTMLButtonElement>) => {

    let iconComponent = null

    if (icon) {
        iconComponent = <MaterialIcon
            type={icon}
            style={{
                paddingLeft: iconPosition === "right" && "4px",
                paddingRight: iconPosition === "left" && "4px"
            }}
        />
    }

    return (
        <button
            {...props}
            type={htmlType}
            className={`${props.className} ${size} ${type} ${disabled && "disabled"} ${noPointerEvents ? "no-pointer-events" : ""}`}
            disabled={disabled}
        >
            {
                iconPosition === "left" ? iconComponent : null
            }

            {props.children}

            {
                iconPosition === "right" ? iconComponent : null
            }
        </button>
    );
}

Button.defaultProps = defaultProps;

export default Button;
