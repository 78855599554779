// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Popover} from 'antd';
import {LogoutOutlined} from "@ant-design/icons";
import './index.css';
import MaterialIcon from "../MaterialIcon";

type Props = {
    type?: any,
    userName?: string,
    onLogout?: Function
}

const LogoutButton = ({type, userName, onLogout}: Props) => {
    const {t} = useTranslation();
    const Logout = <Button
        id={'UserButton'}
        className={'UserButton'}
        type={type ? type : 'link'}
        icon={<MaterialIcon type={"person"} />}
    >
        {userName}
    </Button>;

    if(!onLogout) return Logout;

    return <Popover
        content={
            <Button
                id={'LogoutButton'}
                type={'link'}
                onClick={() => onLogout ? onLogout() : null}
                icon={<LogoutOutlined />}
                className={'logoutButton'}
            >
                {t('Logout')}
            </Button>
        }
        title={null}
        placement="bottomRight"
    >
        {Logout}
    </Popover>
};

export default LogoutButton
