import React from 'react';

interface Props {
    type: string,
    style?: any
}

// PV uses the Jossef Material Icon Lib
const MaterialIcon = ({type, style}: Props) => {
    return (
        <i className={`material-icons ${type} $`} style={style}/>
    );
}

export default MaterialIcon;
