// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import pvSmallLogo from "../Logos/pfeiffervacuum-logo-2024-favicon.svg";
import pvLargeLogoNew from "../Logos/pfeiffervacuum-logo-2024.svg";

type Props = {
    onLogoClick?: Function,
}

const Logo = ({onLogoClick}: Props) => {
    const {t} = useTranslation();
    const windowWidth = window.innerWidth;

    return <a id={'Logo'}
              className={'PVLogo'}
              onClick={(e) => {
                  if(onLogoClick) return onLogoClick();

                  //Reload on Logo click
                  e.preventDefault();
                  window.location.reload()
              }}
    >
        {
            windowWidth < 230
                ? <img src={pvSmallLogo} height={40} alt={t('Logo')}/>
                : <img src={pvLargeLogoNew} height={40} alt={t('Logo')}/>
        }
    </a>
};

export default Logo
